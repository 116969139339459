@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.input-box-shadow {
  filter: drop-shadow(-4 px -4 px 44px rbba(0, 0, 0, 0.08));
}

.custom-mt-4 {
  padding-top: 3rem; /* Adjust as needed */
}

.category-card:hover .arrow-icon {
  fill: white;
}

.slider-wrapper {
  position: relative;
  overflow: hidden;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.8;
}

.slick-prev:before,
.slick-next:before {
  color: #537780;
  font-size: 60px;
}

.slick-prev,
.slick-next {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  z-index: 1; /* Place buttons above the cards */
  /* Additional styling for buttons (e.g., background, color, etc.) */
}

.slick-slider {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
}

.slick-prev {
  left: 0%;
  background: ;
}

.slick-next {
  right: 8%;
  background: red; /*to notice it, is white*/
}

.img-small {
  width: 250px; /* Set the desired width for smaller screens */
  height: auto; /* Auto height to maintain aspect ratio */
}

@media (min-width: 1200px) {
  .img-small {
    width: 560px; /* Set a larger width for larger screens */
    /* You can adjust the height accordingly to maintain the aspect ratio */
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
